<template>
  <div>
    <breadcrumb-component
      :pages="[{ pageName: 'Comparador', route: '/comparador' }]"
    />
    <vx-card title="🏢✔🏸Comparador de propiedades" title-color="black">
      <p class="mb-4">
        Puede eliminar la opción que ya no desee en su comparador, eliminándolas
        del listado a continuación:
      </p>
      <vs-chip
        v-for="i in compareList"
        :key="i.id"
        @click="removeItemFromCompareList(i.id)"
        color="primary"
        icon-
        closable
        class="m-1"
        >{{ i.unit_number }}</vs-chip
      >
      <br />
    </vx-card>
    <br />
    <div class="flex flex-wrap sm:full lg:w-full mt-4">
      <div
        class="w-full sm:w-1/2 md:w-3/3 lg:w-1/5 xl:w-1/4 mt-3 p-2"
        v-for="option in compareList"
        :key="option.id"
      >
        <vx-card>
          <div slot="no-body">
            <div>
              <p class="text-2xl pt-5 pl-5 font-medium">
                {{ option.project.name }}
              </p>
              <p class="text-base pt-0 pl-5">
                Unidad: {{ option.unit_number }}
              </p>
            </div>

            <swiper :options="swiperOption">
              <swiper-slide
                :key="index"
                v-for="(img, index) in option.project_unit_type
                  .project_unit_type_images"
              >
                <img class="slider" :src="img.image_url" alt="banner" />
              </swiper-slide>
              <div
                class="swiper-pagination swiper-pagination-white"
                slot="pagination"
              ></div>
              <div
                class="swiper-button-prev swiper-button-white"
                slot="button-prev"
              ></div>
              <div
                class="swiper-button-next swiper-button-white"
                slot="button-next"
              ></div>
            </swiper>
          </div>
          <vs-row v-if="option.tipo_parqueo == 'PRE'" vs-align="flex-start">
            <vs-col vs-justify="center" vs-align="flex-start" vs-w="3">
              <h3>{{ option.number }}</h3>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="flex-end"
              vs-w="9"
            >
              <vs-chip
                v-for="i in option.parkings_assigned"
                :key="i.id"
                color="success"
              >
                <vs-avatar color="success" icon="drive_eta" />
                <span v-if="i.number">{{ i.number }}</span>
              </vs-chip>
            </vs-col>
          </vs-row>
          <vs-row v-if="option.tipo_parqueo == 'FREE'" vs-align="flex-start">
            <vs-col vs-justify="center" vs-align="flex-start" vs-w="3">
              <h3>{{ option.number }}</h3>
            </vs-col>
          </vs-row>

          <vs-divider class="mb-4"></vs-divider>
          <vs-list-item
            icon="layers"
            :title="`Nivel: ${option.level}`"
          ></vs-list-item>
          <vs-list-item
            icon="texture"
            :title="`Área: ${option.living_area} m2`"
          ></vs-list-item>
          <vs-list-item
            icon="local_hotel"
            :title="`Habitaciones: ${option.rooms}`"
          ></vs-list-item>
          <vs-list-item
            icon="bathtub"
            :title="`Baños: ${option.bathrooms}`"
          ></vs-list-item>
          <vs-list-item
            v-show="option.garden_area != null || undefined || 0"
            icon="local_florist"
            :title="`Jardín: ${option.garden_area}`"
          ></vs-list-item>
          <vs-list-item
            v-show="option.balcony_area != null || undefined || 0"
            icon="terrain"
            :title="`Área balcón: ${option.balcony_area}`"
          ></vs-list-item>
          <vs-list-item
            icon="monetization_on"
            :title="`${getFormatCurrency(option)}`"
          ></vs-list-item>
          <div class="flex justify-between flex-wrap">
            <vs-button
              class="mt-4 w-full shadow-lg text-xl"
              @click="linkToQuotes(option.id)"
              color="primary"
              >Cotizar</vs-button
            >
          </div>
          <vs-row>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              class="mt-2"
              vs-align="center"
              vs-w="12"
            >
              <vs-button
                @click="SendToDetails(option.id, option.project.id)"
                type="flat"
                line-origin="center"
                color="dark"
                >Ver Detalles</vs-button
              >
            </vs-col>
          </vs-row>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.min.css";
import { mapState, mapGetters, mapActions } from "vuex";
import BreadcrumbComponent from "../../layouts/components/BreadcrumbComponent.vue";
import currencies from "./../../helpers/currencies";
import { swiper, swiperSlide } from "vue-awesome-swiper";

export default {
  components: { BreadcrumbComponent, swiper, swiperSlide },
  data() {
    return {
      swiperOption: {
        spaceBetween: 30,
        effect: "fade",
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      }
    };
  },
  computed: {
    ...mapGetters("property", ["compareList"]),
    ...mapState("auth", ["user"])
  },
  methods: {
    SendToDetails(propertyId, projectId) {
      this.$store.dispatch("property/setSelectedPropertyId", { propertyId });
      this.$store.dispatch("project/currentProject", { projectId });
      this.$router.push({ name: "Apartamento", params: { Id: propertyId } });
    },
    dispatchProperty(propertyId) {
      this.$store.dispatch("property/setSelectedPropertyId", { propertyId });
    },
    linkToQuotes(propertyId) {
      this.dispatchProperty(propertyId);
      this.$router.push({
        path: "/generar-cotizacion/",
        params: { id: propertyId }
      });
    },
    getCover(item) {
      if (item.project_unit_type.img_url == "") {
        return null;
      } else {
        return item.project_unit_type.img_url;
      }
    },
    ...mapActions("property", ["removeItemFromCompareList"]),
    getFormatCurrency(property) {
      const currencySymbol = property.project.country_currency
        ? property.project.country_currency.currency.symbol
        : null;

      return currencies.currencyFormatter({
        value: property.price,
        currency: currencySymbol
      });
    }
  }
};
</script>

<style lang="scss">
a {
  color: #303030 !important;
}
a:hover {
  color: #00b158 !important;
}
.vs-list--title {
  font-weight: 400 !important;
  font-size: 16px;
}
</style>
